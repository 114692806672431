import axios from 'axios'

axios.defaults.baseURL = 'https://www.sunsemweb.com/api/';

export function _getTemplates(data) {
	return axios.post('getTemplate', data);
}

export function _getMenu() {
	return axios.post('getIndustryType');
}

export function setCookie(e, n) {
	let a = new Date();
    a.setTime(a.getTime() + 2592e6);
    document.cookie = e + "=" + escape(n) + ";expires=" + a.toGMTString() + ";path=/;";
}
export function getCookie(e) {
	let n = document.cookie.match(new RegExp("(^| )" + e + "=([^;]*)(;|$)"));
    return null != n ? unescape(n[2]) : null
}

export function delCookie(e) {
	let n = new Date();
    n.setTime(n.getTime() - 1);
    let o = getCookie(e);
    null != o && (document.cookie = e + "=" + o + ";expires=" + n.toGMTString())
}

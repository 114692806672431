import React,{Component} from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { _getTemplates } from '../plugs/api';
import { setCookie } from '../plugs/cookie';

export class Templates extends Component{

	constructor(props){
        super(props);
        this.state = {
        	templates: [],
        }
    }

    async getTemplates(id,page){
		setCookie('type_id',id);
		const res = await _getTemplates({type_id: id,page: page});
		if(res.data.code === 200){
	    	let result = res.data.result;
	      	this.setState({
	      		page: result.current_page,
	      		last_page: result.last_page,
	      		templates: result.data,
	      		loading: true,
	      	});
		}
	}

	componentDidMount(){
		this.getTemplates(this.props.match.params.type_id,this.props.match.params.page);
	}

	componentWillUnmount(){
		this.setState=(state,callback)=>{
	        return;
	    };
	}

	componentWillReceiveProps(nextProps){

		const _id = parseInt(this.props.match.params.type_id),
		_page = this.state.page,
		n_id = parseInt(nextProps.match.params.type_id),
		n_page = parseInt(nextProps.match.params.page);

	    if(_id !== n_id){
	    	this.setState({loading: false});
	      	this.getTemplates(n_id);
	    }
	    if(_page !== n_page){
	    	this.setState({loading: false});
	      	this.getTemplates(n_id,n_page);
	    }
	}

   	render(){

   		const type_id = !!this.props.match.params.type_id ? this.props.match.params.type_id : 0;

   		var renderPagination=()=>{
			let pages = [];
			for(let i = 1; i <= this.state.last_page; i++) {
				pages.push(<li key={i} className={this.state.page === i ? 'active' : null }><Link to={'/type/'+type_id+'/'+i}>{i}</Link></li>)
			}
			return pages
		}

		var ranks=()=>{
			let ranks = [];
			for(let i = 1; i <= 5; i++) {
				ranks.push(<svg key={i} viewBox="0 0 22 22"><path fill="#FFAB00" d="M17.7,7.1l-4.4-0.6c-0.1,0-0.2-0.1-0.2-0.2l-1.9-4c-0.1-0.2-0.3-0.4-0.6-0.6C9.9,1.5,9.2,1.7,8.9,2.3L6.9,6.3 C6.9,6.3,6.8,6.4,6.7,6.4L2.3,7.1C2,7.1,1.8,7.3,1.6,7.4c-0.5,0.5-0.5,1.3,0,1.8l3.1,3.1c0.1,0.1,0.1,0.2,0.1,0.3l-0.7,4.4 c-0.1,0.2,0,0.5,0.1,0.8c0.3,0.6,1.1,0.8,1.7,0.5l3.9-2.1c0.1,0,0.2,0,0.3,0l3.9,2.1c0.2,0.1,0.5,0.2,0.8,0.1c0.7-0.1,1.1-0.7,1-1.4 l-0.7-4.4c0-0.1,0-0.2,0.1-0.3l3.2-3.1c0.2-0.2,0.3-0.4,0.4-0.7C18.8,7.8,18.4,7.2,17.7,7.1z"></path><path d="M14.1,11.4c-0.3,0.3-0.5,0.8-0.4,1.3l0.7,4.2l-3.8-2c-0.4-0.2-0.9-0.2-1.4,0l-3.8,2l0.7-4.2 c0.1-0.5-0.1-1-0.4-1.3l-3-3L7,7.8C7.5,7.7,7.9,7.4,8.1,7L10,3.2L11.8,7c0.2,0.4,0.7,0.7,1.1,0.8l4.2,0.6 C17.2,8.4,14.1,11.4,14.1,11.4z"></path></svg>)
			}
			return ranks
		}

		return (

			<div className="content-template">
				<div className="content-row">
					<div className={["template-block", this.state.loading ? "loaded" : null].join(' ')}>
					{
						this.state.templates.map((template) => {
		   						return (
		   							<div className="template-grid template-grid-5" key={template.id}>
										<div className="template-grid-main">
											
											<span className={template.is_new===1?"new-thumbnail-tag":null}></span>

											<a className="thumbnail" target="_blank" rel="noreferrer" href={this.$baceUrl + 'preview/'+template.id}>
												<picture>
													<img src={this.$baceUrl + template.s_cover_url} alt="" />
												</picture>
											</a>
											<div className="template-grid-main-mask">
												<div className="template-info">
													<a href={this.$baceUrl + 'details/'+template.id} target="_blank" rel="noreferrer"><h4 className="paragraph-limit">{template.title}</h4></a>
													<div className="flexd desinger">
							                            <a href="/" target="_blank" rel="noreferrer"><img src={this.$baceUrl + template.designer.headpic} width="25" height="25" alt="" /><span>{template.designer.name}</span></a>
														<div className="tag">
														{template.keywords.map((keyword) => {
															return (<span key={keyword}>{keyword}</span>)
														})}
														</div>
													</div>
													<div className="flexd other">
														<div className="rating" data-rating={template.star}>
															{ranks()}
														</div>
														<div className="price"><span>￥</span>{template.price}</div>
													</div>
												</div>
												<div className="actions">
													<div className="actions-btns">
														<a className="view btn-link" href={this.$baceUrl + 'preview/'+template.id} target="_blank" rel="noreferrer"><span>预览网站</span> <span className="btn-overlay"></span></a>
														<a className="detail btn-link" href={this.$baceUrl + 'details/'+template.id} target="_blank" rel="noreferrer"><span>查看详情</span> <span className="btn-overlay"></span></a>
													</div>
													<div className="actions-btn-item">
														<i className="icon-eye"></i>
														<div className="count">{template.view_count}</div>
													</div>
													<div className="actions-btn-item">
														<i className="icon-thumbs-up"></i>
														<div className="count">{template.favorite_count >= 1000 ? '999+' : template.favorite_count}</div>
													</div>
													<div className="actions-btn-item">
														<i className="icon-heart"></i>
														<div className="count">{template.likes >= 1000 ? '999+' : template.likes}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
		   						)
		   					})	
						}
					</div>
				</div>
				{this.state.last_page>1 ? <div className="template-pagination"><ul className="pagination">{renderPagination()}</ul></div> : null}
			</div>	
		);
	}
}

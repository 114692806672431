import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router,Route } from "react-router-dom";
import { Menu } from "./data/menu";
import { Templates } from "./data/templates";

// import './static/css/template.min.css';

React.Component.prototype.$baceUrl = 'https://www.sunsemweb.com/';

ReactDOM.render(
	<Router>
      	<Menu />
		<Route exact strict path="/" component={Templates} />
		<Route exact path="/type/:type_id" component={Templates} />
		<Route path="/type/:type_id/:page" component={Templates} />
	</Router>
  ,
  document.getElementById("root")
);